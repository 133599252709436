import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import LogoPlus from '../images/tmtg_plus_light_old.svg'
import LogoNews from '../images/tmtg_news_light_old.svg'
import TruthLogo from '../images/truth-social-logo.svg'
import TruthBg from '../images/truth-social-bg.jpg'
import FilmBg from '../images/film.jpg'
import AmericaBg from '../images/america.jpg'
import RoadBg from '../images/road.jpg'
import HomeTruthVideo from '../components/homeTruthVideo'

const brands = [
  {
    title: "Truth Social",
    source: TruthBg,
    logo: TruthLogo
  },
  {
    title: "tmtg plus",
    source: FilmBg,
    logo: LogoPlus
  },
  {
    title: "tmtg news",
    source: "https://images.unsplash.com/photo-1581092795360-fd1ca04f0952?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1287&q=80",
    logo: LogoNews
  }
]

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[]} title='Home' />
      <div className="relative">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover mirror backdrop-filter backdrop-grayscale backdrop-blur-md backdrop-contrast-200"
            src={AmericaBg}
            alt="American Background Image"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-gray-600 to-gray-50 mix-blend-multiply" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-wide text-white filter drop-shadow-lg mb-8">
            <span className="inline-block text-2xl lg:text-3xl leading-8 mb-4">Trump Media &amp; Technology Group</span>
            <br />
            <span className="text-5xl lg:text-8xl leading-none">
              A Uniting Force For Freedom of Expression
            </span>
            <br />
          </h1>
          <p className="leading-relaxed mb-3 text-xl lg:text-3xl text-white">
            No Political Discrimination
          </p>
          <p className="leading-relaxed mb-3 text-xl lg:text-3xl text-white">
            Canceling Cancel Culture
          </p>
          <p className="leading-relaxed mb-3 text-xl lg:text-3xl text-white">
            Standing Up to Big Tech
          </p>
          <a target="_blank" href="https://truthsocial.com" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-8" rel="noreferrer">Follow the TRUTH</a>
        </div>
      </div>

      <section id="brands" className="bg-gray-50">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-800 sm:text-5xl">
            <span className="block">Our Brands</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-600">
            We&apos;re building the future of media and technology
          </p>
        </div>

        <div className="relative max-w-full mx-auto pb-24 px-4 sm:px-6 lg:px-8">
          <ul role="list" className="grid gap-x-4 gap-y-8 grid-cols-1 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:gap-x-8">
            {brands.map((brand) => (
              <li key={brand.source} className="relative">
                <div className="group block w-full rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-blue-500 overflow-hidden">
                  <div className="w-full h-full absolute flex items-center justify-center pointer-events-none p-4">
                    <img src={brand.logo} alt={brand.title} className="z-10 h-24 mx-auto" />
                  </div>
                  <img src={brand.source} alt="" className="object-cover h-96 w-full filter blur-sm " />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <HomeTruthVideo />

      <section id="mission" className="relative bg-gray-800">
        <div className="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src={RoadBg}
            alt="Road Image Background"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <p className="mt-2 text-white text-3xl font-bold tracking-tight sm:text-4xl">Our Mission</p>
            <p className="mt-3 text-lg text-gray-300">
              TRUTH Social&apos;s mission is to end Big Tech&apos;s assault on free speech by opening up the Internet and giving the American people their voices back.
              <br/>
            </p>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default IndexPage
